<script setup lang="ts">
import { Dayjs } from "dayjs";

import type { Promotion } from "@/types";

type PromotionType = Promotion["type"];

const emit = defineEmits<{ (event: "onCountDownStop"): void }>();

const props = defineProps<{
	showAvailable?: boolean;
	enabled?: boolean;
	image: string;
	coins: number;
	entries: number;
	date: string | number | Dayjs;
	type?: PromotionType;
}>();

const { t } = useT();
const { open } = useAppModals();

const onCountDownStop = () => {
	emit("onCountDownStop");
};

const { durationLeft: promotionTime, reset } = useCountdown({
	timestamp: props.date,
	formatToken: "H[:]mm[:]ss",
	onCountdownStop: onCountDownStop
});

const handleClick = () => {
	open("LazyOModalBundle");
};

if (process.client) {
	watch(
		() => props.date,
		(value) => {
			if (value) {
				reset(value, "H[:]mm[:]ss");
			}
		}
	);
}
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 271,
			height: 492,
			src: image,
			alt: 'bundle promo',
			format: 'avif',
			loading: 'lazy'
		}"
		width="328px"
		height="246px"
		data-tid="card-img"
		@click="handleClick"
	>
		<template #top>
			<div class="badge-wrapper">
				<ABadge :background="'var(--gradients-g-6)'" variant="info" autosize>
					<AText class="badge" :size="10" :modifiers="['bold', 'uppercase']">
						{{ t(`bundle.promo.badge.${type}`) }}
					</AText>
				</ABadge>
			</div>
			<AText v-if="showAvailable && enabled" :size="12" :modifiers="['semibold']" class="label-time">
				{{ t("bundle.promo.timer.ends") }}
				<AText :size="12" :modifiers="['extrabold']" class="label-time" gradient="var(--goeteborg)">
					<span>{{ promotionTime }}</span>
				</AText>
			</AText>
			<AText class="title" :size="12" :modifiers="['bold', 'uppercase']">{{ t("bundle.promo.txt.upTo") }}</AText>
		</template>
		<template #bottom>
			<div class="box">
				<MPrizeFund v-if="coins" variant="coins" img="/nuxt-img/prizes/funcoins@2x.png" :icon-size="24">
					<AText :size="32" class="text-tlalnepantla text-count" :modifiers="['semibold', 'uppercase', 'condensed']">
						{{ numberFormat(coins) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund v-if="entries" variant="entries" img="/nuxt-img/prizes/citycoins@2x.png" :icon-size="24">
					<AText :size="32" class="text-tlalnepantla text-count" :modifiers="['semibold', 'uppercase', 'condensed']">
						{{ numberFormat(entries) }}
					</AText>
					<AText :size="10" class="count-after" :modifiers="['bold', 'uppercase', 'condensed']">
						{{ t("bundle.promo.txt.freeCC") }}
					</AText>
				</MPrizeFund>
				<AButton variant="primary" size="s" class="promo" data-tid="promo-buy-now">
					<AText :modifiers="['semibold']" class="text-tlalnepantla">{{ t("bundle.promo.btn.more") }}</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	color: var(--coro);
	background: linear-gradient(119deg, #f12ea9 0%, #792bdc 34.5%, #13d8d8 69.5%, #13d8a9 98%);

	&:deep(> img) {
		margin: auto;
	}

	&:deep(.top) {
		justify-content: space-between;
	}

	.badge-wrapper {
		min-height: 22px;
	}

	.badge {
		padding: 0 4px;
		box-shadow: none;
	}

	:deep(.prize) {
		gap: 4px;

		&.coins {
			img {
				filter: drop-shadow(0 0 6px var(--cheremushki));
			}
		}

		&.entries {
			img {
				filter: drop-shadow(0 0 6px var(--сirebon));
			}
		}

		.text-count {
			text-shadow: 0 4px 4px var(--coronel);
		}

		.count-after {
			text-align: left;
			max-width: 60px;
			font-family: var(--font-family);
			text-shadow: 0 4px 4px var(--coronel);
		}
	}

	.title {
		padding: 2px 0;
	}

	.disabled {
		gap: 8px;
		box-shadow: 0px 4px 10px 0px var(--chile);
		opacity: 1 !important;

		.icon {
			font-size: 24px;
		}
	}
}

.box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	width: 100%;
	height: 100%;
	gap: 8px;

	button {
		min-width: 140px;
		width: max-content;
	}
}

.foot {
	position: absolute;
	bottom: 15px;
	left: 55px;
	text-align: left;
}
</style>
